
export const FOUR_WORDS_ABOUT = [
    {
        title: 'Professional educators to write your course  for you',
        super: 'About Learning Curve',
        spacing: 'pt-5 pb-3',
        content: [
            'When you first decided that creating a course would be an excellent way to monetize your skills while teaching others, it was exhilarating! Build once, sell many times — finally a way to scale beyond just you.',             
            'However, creating a course isn\'t as easy you first thought. Staring at the blank screen with the blinking cursor is intimidating. How do I start? Mind dump everything you know onto the page and then hope some form of structure evolves? What format should it be, PDF, e-book, videos? Do I have to do videos — that\'s going to take a long time and a lot of equipment. How do host — gum road? Thinkific, Kajabi, teachable? ' ,
            'Then the self-doubt starts creeping in. What if I\'m missing something? Am I actually an expert in this? Why even make this course? There are so many courses already online with much more accomplished people? ',
            'Most of the time, people will end up quitting before they even start or if they persevere through,  throw together a mind dump on PDF or a series of unrelated videos which has a 1% completion rate. ',
            'But it doesn\'t need to be this way. Using a learner-centric model, we can design a course built around the specific learning outcomes we want. ',
            'You are an expert in your niche and we are experts in learning. '
        ]
    },
]