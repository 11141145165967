export const WHY_LEARNING_CURVE = [
    {
        title: '🔥 We’re learning experts',
        description: 'Each member of our team has to learn about learning and user experience design before they start. This means that our content is clear, concise and meaningful to a learner.'
    },
    {
        title: '🏎️ We have a strong track record',
        description: 'We have built more than 50 courses in the last four years spanning from high school education products to corporate learning for listed companies.'
    },
    {
        title: '🎥 We consider all mediums of learning',
        description: 'From screen recordings for learning no-code tools to soft skills, we will do it all. We work with a tight-knit group of animators, videographers, copywriters and educators to ensure that your content is in a medium that you envision.'
    },
    {
        title: '🧠 We work with experts',
        description: 'Bonsai to the blockchain. We will gather information from experts and make sure that your content is best practice. '
    },
]