export const CUSTOMER_LOGOS = [
    [
        {
            image: 'https://res.cloudinary.com/dmcrkqndq/image/upload/v1624514212/image_12_mjl9l2.png',
            imageWebp: 'https://res.cloudinary.com/dmcrkqndq/image/upload/v1624514212/image_12_mjl9l2.webp',
            alt: 'Diversitas Logo',
            link: 'https://diversitas.co/',
            p: true
        },
        {
            image: 'https://res.cloudinary.com/dmcrkqndq/image/upload/v1624514212/Frame_e6z9x1.png',
            imageWebp: 'https://res.cloudinary.com/dmcrkqndq/image/upload/v1624514212/Frame_e6z9x1.webp',
            alt: 'Autex Logo',
            link: 'https://www.autexglobal.com/nz/',

        },
        {
            image: 'https://res.cloudinary.com/dmcrkqndq/image/upload/v1624514212/image_6_xiudgv.png',
            imageWebp: 'https://res.cloudinary.com/dmcrkqndq/image/upload/v1624514212/image_6_xiudgv.webp',
            alt: 'Aro Digital Logo',
            space: '1',
            link: 'https://aro.digital/'
        },
        {
            image: 'https://res.cloudinary.com/dmcrkqndq/image/upload/v1624514213/image_20_nued7u.png',
            imageWebp: 'https://res.cloudinary.com/dmcrkqndq/image/upload/v1624514213/image_20_nued7u.webp',
            alt: 'Rush Digital Logo',
            space: '1',
            link: 'https://rush.co.nz/',
            p: true
        },
        {
            image: 'https://res.cloudinary.com/dmcrkqndq/image/upload/v1624514213/Rectangle_1_u18pbv.png',
            imageWebp: 'https://res.cloudinary.com/dmcrkqndq/image/upload/v1624514213/Rectangle_1_u18pbv.webp',
            alt: 'Mainfreight Logo',
            space: '',
            link: 'https://www.mainfreight.com/new-zealand/en-nz',
            p: true
        }
    ],
    [
        {
            image: 'https://res.cloudinary.com/dmcrkqndq/image/upload/v1624514212/image_9_u70mv4.png',
            imageWebp: 'https://res.cloudinary.com/dmcrkqndq/image/upload/v1624514212/image_9_u70mv4.webp',
            alt: 'Engage Logo',
            space: '',
            link:'http://engage.org.nz/',
            p: true
        },
        {
            image: 'https://res.cloudinary.com/dmcrkqndq/image/upload/v1624514212/image_8_qjug85.png',
            imageWebp: 'https://res.cloudinary.com/dmcrkqndq/image/upload/v1624514212/image_8_qjug85.webp',
            alt: 'Cultureflow Logo',
            space: '',
            link: 'https://www.cultureflow.co.nz/',
            p: true
        },
        {
            image: 'https://res.cloudinary.com/dmcrkqndq/image/upload/v1624514213/image_22_rpvflr.png',
            imageWebp: 'https://res.cloudinary.com/dmcrkqndq/image/upload/v1624514213/image_22_rpvflr.webp',
            alt: 'Ministry of Social Development',
            space: '',
            link: 'https://msd.govt.nz/',
            p: true
        },
        {
            image: 'https://res.cloudinary.com/dmcrkqndq/image/upload/v1624514213/image_13_lskfwl.png',
            imageWebp: 'https://res.cloudinary.com/dmcrkqndq/image/upload/v1624514213/image_13_lskfwl.webp',
            alt: 'Awhi Logo',
            link: 'https://awhigroup.nz/',
            space: ''
        }
    ],
    [
        {
            image: 'https://res.cloudinary.com/dmcrkqndq/image/upload/v1624514212/image_11_bluloh.png',
            imageWebp: 'https://res.cloudinary.com/dmcrkqndq/image/upload/v1624514212/image_11_bluloh.webp',
            alt: 'The Business Apprenticeship Logo',
            space: '',
            p: true,
            link: 'https://www.businessapprenticeship.co.nz/'
        },
        {
            image: 'https://res.cloudinary.com/dmcrkqndq/image/upload/v1624514213/image_14_uius13.png',
            imageWebp: 'https://res.cloudinary.com/dmcrkqndq/image/upload/v1624514213/image_14_uius13.webp',
            alt: 'CRESCENDO TRUST LOGO',
            space: '',
            p: true,
            link: 'https://www.crescendo.org.nz/'
        },
        {
            image: 'https://res.cloudinary.com/dmcrkqndq/image/upload/v1624514213/Skill_Sprout_cwuxqu.png',
            imageWebp: 'https://res.cloudinary.com/dmcrkqndq/image/upload/v1624514213/Skill_Sprout_cwuxqu.webp',
            alt: 'Skill Sprout Logo',
            space: '1'
        },
        {
            image: 'https://res.cloudinary.com/dmcrkqndq/image/upload/v1624514212/image_7_fqyk5s.png',
            imageWebp: 'https://res.cloudinary.com/dmcrkqndq/image/upload/v1624514212/image_7_fqyk5s.webp',
            alt: 'Your Sales Engine Logo',
            space: '1',
            link: 'http://yoursalesengine.com/'
        },
        {
            image: 'https://res.cloudinary.com/dmcrkqndq/image/upload/v1624514212/image_10_aqowjd.png',
            imageWebp: 'https://res.cloudinary.com/dmcrkqndq/image/upload/v1624514212/image_10_aqowjd.webp',
            alt: 'Brain Badge Logo',
            space: '',
            link:'https://www.brainbadge.org/'
        }
    ],
    [
        {
            image: 'https://res.cloudinary.com/dmcrkqndq/image/upload/v1624514213/image_21_mawnwe.png',
            imageWebp: 'https://res.cloudinary.com/dmcrkqndq/image/upload/v1624514213/image_21_mawnwe.webp',
            alt: 'Breathing Works Logo',
            space: '',
            p: true,
            link: 'https://www.breathingworks.com/'
        },
        {
            image: 'https://res.cloudinary.com/dmcrkqndq/image/upload/v1624514213/image_16_ndhotn.png',
            imageWebp: 'https://res.cloudinary.com/dmcrkqndq/image/upload/v1624514213/image_16_ndhotn.webp',
            alt: 'Money Mentalist Logo',
            space: '',
            link: 'https://www.moneymentalist.com/'
        },
        {
            image: 'https://res.cloudinary.com/dmcrkqndq/image/upload/v1624514211/Frame-1_mln1sy.png',
            imageWebp: 'https://res.cloudinary.com/dmcrkqndq/image/upload/v1624514211/Frame-1_mln1sy.webp',
            alt: 'JTT Med Logo',
            space: '1',
            p: true,
            link: 'https://jttmed.com/'
        },
        {
            image: 'https://res.cloudinary.com/dmcrkqndq/image/upload/v1624514213/image_19_pesyap.png',
            imageWebp: 'https://res.cloudinary.com/dmcrkqndq/image/upload/v1624514213/image_19_pesyap.webp',
            alt: 'QuickSense Logo',
            space: '1',
            p: true,
            link: 'https://wwww.quicksense.org'
        },
        {
            image: 'https://res.cloudinary.com/dmcrkqndq/image/upload/v1624514213/image_18_i8mdkk.png',
            imageWebp: 'https://res.cloudinary.com/dmcrkqndq/image/upload/v1624514213/image_18_i8mdkk.webp',
            alt: 'The Compliance Company Logo',
            space: ''
        }
    ]
]