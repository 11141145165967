import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

//Components
import Section from '../Section/section'

class GetStarted extends React.Component {

    render(){
        return <Section background={this.props.background} center={true}>
            <Col className='d-flex flex-column justify-content-center align-items-center text-center' lg={8}>
                {
                    !!this.props.superImage && 
                                    <figure>
                                        <picture>
                                            <source data-srcset={this.props.superImageWebp} type='image/webp'/>
                                            <img 
                                                width={this.props.superImageWidth} 
                                                height={this.props.superImageHeight} 
                                                data-src={this.props.superImage}
                                                loading={'lazy'}
                                                className={`rounded lazyload ${this.props.superImageClass}`}
                                                alt={this.props.superImageAlt} />
                                        </picture>
                                    </figure>
                }
                <h2 className='h1 mb-3'>{this.props.title}</h2>
                <p dangerouslySetInnerHTML={{__html: this.props.description}}/>
                <div className='d-flex justify-content-around align-items-center'>
                    <Link className='mt-3 mb-3 px-5 btn btn-primary' to='/contact'>Email Us</Link>
                </div>
                {!!this.props.secondaryButton &&
                    <>
                        {
                            this.props.secondaryButtonType == 'internal' ?
                                <Link className='mt-0 btn btn-link --underline' to={this.props.secondaryButtonDestination}>{this.props.secondaryButtonText}</Link>:
                                <Button className='mt-0' variant='link' href={this.props.secondaryButtonDestination}>{this.props.secondaryButtonText}</Button>
                        }
                    </>
                    
                }
            </Col>
        </Section>
    }
}

GetStarted.propTypes = {
    background: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    buttonText: PropTypes.string,
    buttonVariant: PropTypes.string,
    secondaryButton: PropTypes.bool,
    secondaryButtonText: PropTypes.string,
    secondaryButtonType: PropTypes.string,
    secondaryButtonDestination: PropTypes.string,
    superImage: PropTypes.string,
    superImageWebp: PropTypes.string,
    superImageWidth: PropTypes.string,
    superImageHeight: PropTypes.string,
    superImageClass: PropTypes.string,
    superImageAlt: PropTypes.string,
}

GetStarted.defaultProps = {
    background: 'bg-cream',
    title: 'Get started with Learning Curve',
    description: 'Whether you\'re still thinking about if you should create an online course or ready to pull the trigger, book a 30-minute discovery call or email us.',
    buttonText: 'Email Us',
    buttonVariant: 'outline-primary',
    secondaryButton: true,
    secondaryButtonText: 'Unsure? Read our guide',
    secondaryButtonType: 'internal',
    secondaryButtonDestination: '/process'
}

export default GetStarted